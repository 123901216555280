/* 
---------------------------------------------
Blog
--------------------------------------------- 
*/


.our-blog .top-dec {
    text-align: right;
    /* margin-top: -80px; */
}

.our-blog .top-dec img {
    max-width: 270px;
}

.our-blog .left-image {
    position: relative;
}

.our-blog .left-image img {
    width: 100%;
    height: 512px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

.our-blog .left-image .info {
    position: relative;
}

.our-blog .left-image .info .inner-content {
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    /* margin-right: 75px; */
    /* position: absolute; */
    margin-top: -100px;
    padding: 30px;
}


.our-blog .left-image .info .inner-content ul li {
    /* display: inline-block; */
    font-size: 15px;
    color: #000;
    font-weight: 300;
    margin-left: 20px;
    list-style: disc !important;

}

.our-blog .left-image ul.blogInfo li {
    display: inline-block;
    font-size: 15px;
    color: #000;
    font-weight: 300;
    margin: 0 !important;
}

.our-blog .left-image ul li:last-child {
    margin-right: 0px;
}

.our-blog .left-image ul li i {
    color: #ff4d61;
    font-size: 16px;
    margin-right: 8px;
}

.our-blog .left-image h4 {
    font-size: 20px;
    font-weight: 700;
    color: #2a2a2a;
    margin: 20px 0px 15px 0px;
}

.our-blog .left-image h2 {
    font-size: 20px;
    font-weight: 700;
    color: #2a2a2a;
    margin: 20px 0px 15px 0px;
}

.our-blog .left-image p {
    font-size: 16px;
    margin: 20px 0px 15px 0px;
}

.our-blog .left-image h5 {
    font-size: 20px;
    font-weight: 400;
    color: #2a2a2a;
    margin: 20px 0px 15px 0px;
}

.our-blog .left-image .info .main-blue-button {
    position: absolute;
    bottom: -80px;
    left: 0;
}

/* .our-blog .right-list {
    margin-left: 30px;
} */

.our-blog .right-list ul li {
    display: inline-flex;
    width: 100%;
    /* margin-bottom: 30px; */
    border-top: 1px solid #eee;
}

.our-blog .right-list .left-content {
    margin-right: 45px;
}

.our-blog .right-list .left-content span {
    font-size: 12px;
    color: #afafaf;
    font-weight: 300;
}

.our-blog .right-list .left-content span i {
    color: #ff4d61;
    font-size: 10px;
    margin-right: 8px;
}

.our-blog .right-list .left-content h4 {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    margin: 10px 0px 15px 0px;
}

.our-blog .right-list p {
    font-size: 12px;
    color: #ffffff;
}


.our-blog .right-list .right-image img {
    width: 250px;
    height: 80px;
    border-radius: 20px;
    object-fit: cover;
}
