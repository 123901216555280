.fr05 {
    font-size: 0.8rem !important;
}

.fr1{
    font-size: 1rem !important;
}

.fr2{
    font-size: 12rem !important;
}


/* coupon css */

.coupon-card {
    width: 400px;
    border-radius: 20px;
    background: black;
    border: none;
    color: #fff;
    height: 200px;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  
  .coupon-card span {
    font-size: 24px;
  }
  
  .image {
    position: absolute;
    opacity: .1;
    left: 0;
    top: 0;
  }
  
  .image2 {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: .1;
  }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    /* Your styles here */

}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    /* Your styles here */
    .checkoutView{
        padding-top: 5vh;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    /* Your styles here */
    .checkoutView{
        padding-top: 15vh;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    /* Your styles here */
    .checkoutView{
        padding-top: 15vh;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /* Your styles here */
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    /* Your styles here */
}