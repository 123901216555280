.video-card {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    opacity: 1;
    transition: opacity 0.3s ease;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
}

.video-overlay h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.highlight {
    color: #d9214e;
}

.action-button {
    padding: 10px 20px;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.action-button:hover {
    background: #d9214e;
    color: white;
}

.arrow {
    margin-left: 10px;
}

.gradient-text {
    background: linear-gradient(90deg, rgb(174, 134, 37) 0%, rgba(247, 239, 138, 1) 35%, rgba(210, 172, 71, 1) 80%, rgba(237, 201, 103, 1) 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}