.wrap-sidebar {
    position: fixed;
    z-index: 1100;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.sidebar {
    position: fixed;
    z-index: 1100;
    width: 400px;
    max-width: calc(100% - 30px);
    height: 100vh;
    top: 0;
    right: -400px;
    background-color: #ffffff;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.34;
    transition: all 0.4s;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.18);
    -o-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.18);
    -ms-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.18);
    overflow-y: auto;
    overflow-x: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

.sidebar-menu-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.show-sidebar {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.6);
}

.no-scroll {
    overflow: hidden;
}

.show-sidebar .sidebar {
    right: 0;
}

.mobile-menu-toggle-icon {
    padding: 15px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .wrap-sidebar {
        width: 100%;
        /* Full width for mobile */
        right: -100%;
        /* Hidden by default */
    }

    .show-sidebar {
        right: 0;
    }
}


.timeline {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
    padding: 0;
    margin: 0;
}

.p-4 {
    padding: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
}

.tl-item>* {
    padding: 10px;
    font-size: 12px;
}

.tl-item .avatar {
    z-index: 2;
}

.tl-item:last-child .tl-dot:after {
    display: none;
}

.tl-item.active .tl-dot:before {
    border-color: #000000;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
}

.tl-item:last-child .tl-dot:after {
    display: none;
}

.tl-item.active .tl-dot:before {
    border-color: #000000;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
    content: "";
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
}

tl-item.active .tl-dot:before {
    border-color: #cc0000;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
    content: "";
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0;
}

.tl-content p:last-child {
    margin-bottom: 0;
}

.tl-date {
    font-size: 0.85em;
    margin-top: 2px;
    /* min-width: 100px;
  max-width: 100px; */
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    /* Your styles here */

}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {

    /* Your styles here */
    .trackorderView {
        padding-top: 5vh;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

    /* Your styles here */
    .trackorderView {
        padding-top: 15vh;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    /* Your styles here */
    .trackorderView {
        padding-top: 15vh;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /* Your styles here */
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    /* Your styles here */
}