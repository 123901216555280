.ShopByGenderTag {
    position: relative;
    border-radius: 18px;
    color: #fff;
    display: inline-block;
    /* Ensure the line only spans the text width */
}

.ShopByGenderTag::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    /* Adjust thickness of the line */
    bottom: 0;
    /* Position the line below the text */
    left: 0;
    background: linear-gradient(90deg, rgb(174, 134, 37) 0%, rgba(247, 239, 138, 1) 35%, rgba(210, 172, 71, 1) 80%, rgba(237, 201, 103, 1) 90%);
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.card-hover:hover .ShopByGenderTag::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.rotate-down {
    transition: transform 0.3s ease;
}

.card-hover:hover .rotate-down {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}


.card-img-container {
    position: relative;
    overflow: hidden;
    /* Hide overflow from the zoom effect */
    border-radius: 10px;
    /* Ensure corners are rounded */
}

.card-img-container img {
    transition: transform 0.3s ease-in-out;
    /* Smooth zoom transition */
}

.card-img-container:hover img {
    transform: scale(1.1);
    transition: transform 1s ease-in-out;
    /* Zoom effect */
}

.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    /* Dark overlay */
    transition: opacity 0.3s ease;
    opacity: 1;
}


.gendersCard {
    /* width: 18rem; */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative
}

