.title {
    font-weight: bold !important;
    font-size: 1.6rem;
}

.swiper-arrows {
    padding: 10px !important;
    border-radius: 50% !important;
    background-color: #000 !important;
    color: #999 !important;
}

@media (max-width:480px) {
    .title {
        font-weight: bold;
        font-size: 1rem;
    }
}



/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .title {
        font-size: 1rem;
    }

    .swiper-arrows {
        padding: 2px !important;
    }

    .top-categories .slick-track {
        margin-left: 0.8rem !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    /* Your styles here */
    .top-categories .slick-track {
        margin-left: 3rem !important;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

    .top-categories .slick-track {
        margin-left: 3rem !important;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /* Your styles here */
    .top-categories .slick-track {
        margin-left: 3rem !important;
    }
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    /* Your styles here */
}