
/* floating buttons ---------------------------------------------------------------*/
.float-call {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 999;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.float-call:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.float-call .call-button {
    display: inline-block;
    background-color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
    padding: 8px 10px;
    border-radius: 23px;
    letter-spacing: 0.25px;
    border: 1px solid #000;
    outline: none;
    transition: all .3s;
}

.float-call .call-button img.callicon {
    width: 25px;
}


/* floating buttons end--------------------------------------------------------------- */
