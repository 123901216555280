.custom-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: top 0.5s ease-in-out;
  /* Added transition for smooth animation */
}

.header-top {
  width: 100%;
  background: #EFEFEF !important;
  transition: top 0.5s ease-in-out;
  /* Added transition for smooth animation */
}

.header-top-text {
  font-size: 0.8rem !important;
}

.top-header {
  padding: 10px 0;
  background: #000000 !important;
}

.bottom-header {
  margin-top: 20px;
  /* Adjust margin as needed */
}

.logo {
  text-align: left;
}

.icons .icon-btn {
  margin-left: 10px;
}

.icon-text-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
  /* Adjust margin as needed */
}

.icon-text-container .icon {
  margin-right: 5px;
}

.icon-text-container .text {
  font-size: 14px;
}

.icon-text-container .text-small {
  font-size: 9px;
}

.icon-badge {
  /* background: white; */
  color: white;
  padding: 2px;
  font-size: 0.8rem;
  width: 15px;
  height: 15px;
  border-radius: 7.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wishlist-badge {
  position: absolute;
  right: -10px;
  top: -5px;
}

.cart-badge {
  position: absolute;
  right: -10px;
  top: -5px;
}

.menu-navbar {
  padding-top: 10px;
}

.bottomNavbarCategories {
  position: relative;
  padding: auto;
  border-radius: 18px;
  color: #fff;
  /* font-size: 20px !important; */
}

.bottomNavbarCategories::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 5px;
  height: 0.05em;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(174, 134, 37) 0%, rgba(247, 239, 138, 1) 35%, rgba(210, 172, 71, 1) 80%, rgba(237, 201, 103, 1) 90%);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.bottomNavbarCategories:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.dropdown-options a,
dropdown-options .btn-link {
  color: #000000;
  font-weight: 800;
  line-height: 30px;
}

/* @media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {
  .search-bar {
    display: none;
  }

  .menu-navbar {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .search-bar {
    display: none;
  }

  .header-top-text {
    font-size: 0.6rem !important;
  }
} */


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

  /* Your styles here */
  .homeView {
    padding-top: 12vh;
  }

  .menu-navbar {
    display: none !important;
  }

  .menu-top-header {
    display: none !important;
  }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

  /* Your styles here */
  .menu-top-header {
    display: none !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

  /* Your styles here */
  .homeView {
    padding-top: 14vh;
  }

  .hidden {
    top: -38px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  /* Your styles here */
  .homeView {
    padding-top: 0vh;
  }

  .hidden {
    top: -38px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Your styles here */
  .hidden {
    top: -38px;
  }
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
  .hidden {
    top: -38px;
  }
}