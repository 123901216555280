/* StoreLocator.css */
.store-list {
    overflow-y: auto;
    max-height: 600px; /* Adjust based on your layout */
  }
  
  .store-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    padding: 10px;
    border-radius: 8px;
  }
  
  .store-item:hover {
    background-color: #1c1d1f;
  }
  
  .store-item.selected {
    background-color: #1c1d1f;
  }
  
  .store-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 1rem;
    border-radius: 8px;
  }
  
  .store-details {
    flex: 1;
  }
  
  .store-name {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: #FFf;
  }
  
  .store-address {
    margin: 0;
    color: #FFf;
    font-size: 0.8rem;
  }
  
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    /* Your styles here */

}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {

    /* Your styles here */
    .storeLocatorView {
        padding-top: 5vh;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

    /* Your styles here */
    .storeLocatorView {
        padding-top: 15vh;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    /* Your styles here */
    .storeLocatorView {
        padding-top: 15vh;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /* Your styles here */
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    /* Your styles here */
}