/* Privacy Policy */

.privacy-policy {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
}

.privacy-policy h1,
.privacy-policy h2 {
    color: white;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
}

.privacy-policy p,
.privacy-policy ul {
    color: white;
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;
}

.privacy-policy ul {
    list-style-type: none;
    padding-left: 0;
    color: white;
}

.privacy-policy ul li::before {
    content: "• ";
    font-weight: bold;
    margin-right: 5px;
}


/* terms and conditions */

.terms-of-service {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
}

.terms-of-service h1,
.terms-of-service h2,
.terms-of-service h3 {
    color: white;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
}

.terms-of-service p,
.terms-of-service ul {
    color: white;
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;

}

.terms-of-service ul {
    list-style-type: none;
    padding-left: 0;
    color: white;
}

.terms-of-service ul li::before {
    content: "• ";
    font-weight: bold;
    margin-right: 5px;
}


/* Cookie Policy */
.cookie-policy {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
}

.cookie-policy h1,
.cookie-policy h2,
.cookie-policy h3 {
    color: white;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
}

.cookie-policy p,
.cookie-policy ul {
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;
    color: white;
}

.cookie-policy ul {
    list-style-type: none;
    padding-left: 0;
}

.cookie-policy ul li::before {
    content: "• ";
    font-weight: bold;
    margin-right: 5px;
}


/* Disclaimer */

.disclaimer {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.disclaimer h1,
.disclaimer h2 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: white;
}

.disclaimer p {
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;

}


/* CopyrightNotice */

.copyright-notice {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.copyright-notice h1,
.copyright-notice h2,
.copyright-notice h3 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: white;
}

.copyright-notice p {
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;
    color: white;
}


/* ExchangeReturnPolicy */

.exchange-return-policy {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.exchange-return-policy h1,
.exchange-return-policy h2 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: white;
}

.exchange-return-policy p {
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;
    color: white;
}


/* ShippingDeliveryPolicy */

.shipping-delivery-policy {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;}

.shipping-delivery-policy h1,
.shipping-delivery-policy h2 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: white;
}

.shipping-delivery-policy p {
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;
    color: white;
}

/* CommunityGuidelines */

.community-guidelines {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.community-guidelines h1,
.community-guidelines h2,
.community-guidelines h3 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: white;

}

.community-guidelines p {
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;
    color: white;
}

/* AccessibilityPolicy */

.accessibility-policy {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.accessibility-policy h1,
.accessibility-policy h2,
.accessibility-policy h3 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: white;
}

.accessibility-policy p {
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;
    color: white;
}

/* ContactInformation */

.contact-information {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 1.6;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.contact-information h1,
.contact-information h2 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: white;
}

.contact-information p,
.contact-information address {
    text-align: justify;
    margin-bottom: 20px;
    font-size: 12px;
    color: white;
}

.contact-information a {
    color: white;
    text-decoration: none;
}

.contact-information a:hover {
    text-decoration: underline;
}