.ProductCategoryView {
  padding-top: 15vh !important
}

.img-thumbnail {
  max-width: 87% !important;
}

.mobile-product-image {
  max-width: 100% !important;
  border-radius: 1rem;
}

.slick-dots .slick-thumbnail {
  width: 50px;
  /* Adjust thumbnail size as needed */
  height: 50px;
  /* Adjust thumbnail size as needed */
  object-fit: cover;
  /* Ensures images cover the thumbnail area */
  border-radius: 4px;
  /* Optional: add border radius */
}

.slick-dots .slick-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-dots li {
  display: inline-block;
  margin-right:40px;
  padding: 0;
}

.slick-dots li button:before{
  font-size: 16px !important;
}

@media (max-width: 480px) {
  .ProductCategoryView {
    padding-top: 10vh !important
  }
}

.custom-tabs .nav-link {
  flex: 1;
  text-align: center;
  color: gray;
}

.custom-tabs .nav-link.active {
  color: white;
  border-bottom: 1px solid #fff;
  /* Custom color for the underline */
  background-color: transparent;
}

.custom-tabs .nav-link:hover {
  color: white;
  background-color: transparent;
}

.custom-tabs .nav-tabs {
  border-bottom: none;
}

.content-col {
  position: relative;
}

.text-overlay {
  position: relative;
  height: auto;
}

.text-section {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: auto;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
}

.marquee-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.notes-image-slider {
  display: flex;
}

.notes-image-slider img {
  height: 10rem;
  width: 10rem;
  border-radius: 10px;
}

.accordion-button,
.collapsed {
  background-color: transparent;
  color: white;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}

.accordion-button::after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4gPHBhdGggZD0iTTggMVYxNE0xIDhIMTQiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlV2lkdGg9IjIiLz48L3N2Zz4=');
}

.accordion-button:not(.collapsed)::after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4gPHBhdGggZD0iTTggMVYxNE0xIDhIMTQiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlV2lkdGg9IjIiLz48L3N2Zz4=');
  transform: rotate(45deg);
}

.intensity-details .progress,
.intensity-details.progress-stacked {
  height: 2.5rem;
  border: 1px solid gray;
}

.intensity-details .progress-bar {
  background: black !important;
  padding-left: 10px;
  align-items: flex-start;
  font-size: 1rem;
  border-radius: 2rem;
}

/* MenAllDayComponent.css */
.men-all-day-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid white;
  padding: 10px 20px;
  margin: 20px;
}

.men-section,
.all-day-section {
  display: flex;
  align-items: center;
}

.men-icon {
  color: #0095ff;
  font-size: 2rem;
  margin-right: 10px;
}

.men-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.all-day-text {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}

.all-day-icon {
  font-size: 2rem;
  color: white;
}


/* SevenHoursComponent.css */
.seven-hours-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.circle {
  position: relative;
  width: 150px;
  height: 150px;
  border: 2px solid #ff0000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number {
  font-size: 3rem;
  color: #ff0000;
}

.hours {
  position: absolute;
  right: 15%;
  bottom: 20%;
  font-size: 1rem;
  color: #ff0000;
  transform: rotate(90deg);
}

.arrow {
  position: absolute;
  top: 5%;
  right: 5%;
  transform: rotate(90deg);
}


/*  */

.rating-card {
  background-color: transparent;
  border: none;
}

.rating-card.filled {
  background-color: #ffeb3b; /* Filled color */
}

.rating-card .fa-star {
  font-size: 24px;
}

.rating-card.filled .fa-star {
  color: #ffeb3b; /* Filled star color */
}

.rating-card:hover .fa-star {
  color: #ffeb3b; /* Highlight on hover */
}
