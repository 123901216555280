.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}


.float-whatsapp {
    position: fixed;
    bottom: 62px;
    right: 15px;
    z-index: 999;
}

.float-whatsapp .whatsapp-button {
    display: inline-block;
    background-color: #25d366;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
    padding: 8px 10px;
    border-radius: 23px;
    letter-spacing: 0.25px;
    border: 1px solid #000;
    outline: none;
    transition: all .3s;
}

.float-whatsapp .whatsapp-button img.whatsappicon {
    width: 25px;
}

.whatsapp-button {
    background-color: #25D366;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whatsapp-button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.whatsapp-popup {
    position: absolute;
    bottom: 110px;
    right: -10px;
    background: white;
    border: 2px solid #25D366;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    width: 350px;
    transform: scale(0);
    opacity: 0;
    transform-origin: bottom right;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.whatsapp-popup.open {
    transform: scale(1);
    opacity: 1;
}

.popup-message p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.chat-button {
    background-color: #25D366;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    transition: background-color 0.3s ease;
}

.chat-button:hover {
    background-color: #1ebd5c;
}

.powered-by {
    text-align: center;
    margin-top: 10px;
    font-size: 10px;
    color: #888;
}

.arrow-box {
    position: absolute;
    bottom: -11px;
    right: 15px;
    width: 20px;
    height: 20px;
    background: white;
    border-right: 2px solid #25D366;
    border-bottom: 2px solid #25D366;
    transform: rotate(45deg);
}