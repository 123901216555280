/* VideoCarousel.css */
::-webkit-scrollbar {
  height: 8px;
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


/* Full-screen overlay */
.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0);
  /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Content inside the overlay */
.overlay-content {
  position: relative;
  height: 100vh;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.preview-video-previous {
  height: 3vh;
  width: 440px;
  object-fit: cover;
  border-radius: 0 0 10px 10px;
}

.video-player-main {
  height: 92.5vh;
  width: 440px;
  object-fit: cover;
  border-radius: 10px;
}

.preview-video-next {
  height: 5vh;
  width: 440px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

/* Close button for overlay */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Optionally, add scroll styles for the overlay content */
.video-overlay::-webkit-scrollbar {
  width: 8px;
}

.video-overlay::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.video-overlay::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* CSS to apply transition to the main video */
.main-video {
  transition: opacity 1s ease-in-out;
  /* Adjust timing for smoothness */
}

.expanded-product-details {
  position: absolute;
  bottom: 0px;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  padding-left: 1rem;
}

@media (max-width: 575px) {
  .main-video {
    margin-top: 0 !important;
  }

  .video-player-main {
    height: 100vh;
    border-radius: 0;
  }
  .expanded-product-details {
  bottom: 50px;
  }
}