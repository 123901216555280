.marquee-container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.sliding-text {
    font-size: 5rem;
    color: black;
    -webkit-text-stroke: 1px white;
    margin-right: 100px;
}

.sliding-text::after {
    content: '*';
    margin-left: 100px;
    font-size: 5rem;
}