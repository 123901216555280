 .custom-carousel img {
   height: 400px;
   object-fit: cover;
 }

 .custom-caption {
   background: rgba(0, 0, 0, 0.5);
   padding: 20px;
   border-radius: 10px;
 }

 .top-categories {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
 }

 /* .top-categories .btn {
   width: 120px;
   margin-bottom: 10px;
 } */

 .custom-card {
   border: none !important;
 }

 .custom-footer {
   background: #f8f9fa;
   padding: 20px;
   border-top: 1px solid #ddd;
 }

 .custom-footer p {
   margin: 0;
 }

 :focus-visible {
   outline: none !important;
 }

 .product-image {
   border: 1px solid gray;
   border-radius: 10px !important;
 }

 .slick-slide img {
  display: block !important;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

  /* Your styles here */
  .homeView {
      padding-top: 8vh;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  /* Your styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* Your styles here */
  .homeView {
      padding-top: 90px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Your styles here */
  .homeView {
      padding-top: 0vh;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Your styles here */
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}