.custom-placeholder::placeholder {
    color: rgba(0, 0, 0, 0.842) !important;
    opacity: 1;
}

.fade-out {
    opacity: 1;
    transition: opacity 1s ease-out;
}

.fade-out.fade-out {
    opacity: 0;
}
