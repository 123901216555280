body {
  margin: 0;
  font-family: "Syne" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
}
/* 
.modal-content{
  background-color: transparent !important;
} */
/* Response break points */

/* Extra Small Devices (Mobile phones) */

/* screen size px from 1px to 575px */
@media (max-width: 575px) {
  .menu-navbar {
    display: none !important;
  }

  .sectionView {
    padding-top: 90px;
  }

  .rounded-start-sm {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
}

/* screen size px from 576px to 768px */
@media (min-width: 576px) {}

/* Small Devices (Tablets): */
/* screen size px from 768px to 1024px */
@media (min-width: 768px) {

  .sectionView {
    padding-top: 120px;
  }

  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }

  .rounded-start-sm {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
}

/* Medium Devices (Laptops and Desktops): */
/* screen size px from 1024px to 1199px */
@media (min-width: 1024px) {
  .sectionView {
    padding-top: 120px;
  }

  .rounded-start-sm {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

/* Large Devices (Large Laptops and Desktops): */
/* screen size px from 1199px to 1200px */
@media (min-width: 1199px) {}

/* Extra Large Devices (Extra Large Laptops and Desktops): */
/* screen size px from 1200px to 1600px */
@media (min-width: 1200px) {}

/* screen size px from 1600px to above */
@media (max-width: 1600px) {}