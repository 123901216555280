.sliderImages {
    border-radius: 25px !important;
    border: 1px solid #ccc !important;
    width: 100% !important;
    object-fit: fill;
    height: auto;
}

.slide-item {
    padding: 15px;
}

.slick-dots {
    bottom: 60px !important;
}

.slick-dots li button:before {
    color: rgb(174, 134, 37) !important;
}

.slick-track {
    margin-left: 0 !important;
}

/* Desktop Slider */
.sliderDesktop {
    display: none !important;
}

/* Mobile Slider */
.sliderMobile {
    display: none !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .sliderMobile {
        display: block !important;
    }

    .slide-item {
        padding: 5px !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    /* Your styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .sliderDesktop {
        display: block !important;
    }

    .sliderImages {
        height: 42vh;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .sliderDesktop {
        display: block !important;
    }

    .sliderImages {
        height: 52vh;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

    /* Your styles here */
    .sliderImages {
        height: 82vh;
    }
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {

    /* Your styles here */
    .sliderImages {
        height: 72vh;
    }
}