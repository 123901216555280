.footer {
    color: #fff;
    padding: 3rem 0;
}

.footer h5, .footer h6 {
    color: #fff;
}

.footer p {
    color: #bbb;
}

.footer ul {
    list-style-type: none;
    padding: 0;
}

.footer ul li {
    margin-bottom: 0.5rem;
    color: #bbb;
}

.footer ul li:hover {
    color: #fff;
}

.footer .social-icons {
    font-size: 1.25rem;
}

.footer .social-icons svg {
    cursor: pointer;
}

.footer .social-icons svg:hover {
    color: #fff;
}

.footer img {
    width: 40px;
    height: auto;
}
