.avatar-lg {
    height: 10rem;
    width: 10rem;
    object-fit: contain;
}

.font-size-18 {
    font-size: 18px !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

a {
    text-decoration: none !important;
}

.prod-qty{
    font-weight: 500;
}

.w-xl {
    min-width: 160px;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    /* Your styles here */

}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    /* Your styles here */
    .cartView{
        padding-top: 5vh;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    /* Your styles here */
    .cartView{
        padding-top: 15vh;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    /* Your styles here */
    .cartView{
        padding-top: 15vh;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    /* Your styles here */
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    /* Your styles here */
}