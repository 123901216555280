

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    /* Your styles here */
    .collectionView {
        padding-top: 8vh;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    /* Your styles here */
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    /* Your styles here */
    .collectionView {
        padding-top: 14vh;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    /* Your styles here */
    .collectionView {
        padding-top: 14vh;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    /* Your styles here */
  }
  
  /* XXL devices (larger desktops, 1400px and up) */
  @media (min-width: 1400px) {
    /* Your styles here */
  }