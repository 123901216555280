.blog-card-large {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background: transparent;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-card-small {
    background: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}


.blog-sm-title {
    font-size: 1rem !important;
    font-weight: bold;
}

.blog-sm-excerpt {
    font-size: 0.8rem !important;
}

.blog-sm-readmore {
    font-size: 0.6rem !important;
}

.blog-card-large .card-img {
    object-fit: cover;
    height: 100%;
}

.blog-card-large .card-img-overlay {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blogs-home .title {
    font-size: 1.75rem;
    font-weight: bold;
}

.blog-img-container {
    position: relative;
    overflow: hidden;
}


.blog-img-container img {
    transition: transform 0.6s ease-in-out;
    /* Smooth zoom transition */
}

.blog-img-container:hover img {
    transform: scale(1.1);
    transition: transform 0.6s ease-in-out;
    /* Zoom effect */
}

/* .gradient-text {
    background: linear-gradient(90deg, #ff8a00, #e52e71);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */
