.video-popup-container {
    position: fixed;
    top: 50%;
    right: -400px;
    transform: translateY(-50%);
    width: 350px;
    height: 650px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: right 0.5s ease-in-out;
    z-index: 1000;
}

.video-popup-container.open {
    right: 15px;
    /* Slide in from the right */
}

.video-popup-content {
    text-align: center;
}

.video-popup-content video {
    width: 100%;
    height: 650px;
    border-radius: 10px;
    object-fit: fill;
}

.video-popup-content button {
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
}

.close-popup {
    cursor: pointer;
    color: #888;
    font-size: 24px;
    margin-right: 10px;
}

.video-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
}

.video-popup-overlay.show {
    display: block;
    cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"%3E%3Ccircle cx="24" cy="24" r="22" fill="white" stroke="white" stroke-width="2"/%3E%3Cline x1="18" y1="18" x2="30" y2="30" stroke="black" stroke-width="2"/%3E%3Cline x1="30" y1="18" x2="18" y2="30" stroke="black" stroke-width="2"/%3E%3C/svg%3E') 24 24, auto;
    animation: cursor-pop 0.6s ease-in-out infinite;
}


@keyframes cursor-pop {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}