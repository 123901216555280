.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f8f9fa; */
  border-bottom: none;
}

.card-header div {
  display: flex;
  align-items: center;
}

.card-header span {
  margin-left: 5px;
}

.card-body {
  text-align: center;
}

.addToCart {
  align-items: center;
  background: #efefef;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  height: 40px;
  width: 40px;
}

/* .card-body img {
    margin-bottom: 15px;
  }
   */
/* .card-body .btn {
  background-color: #e0e0e0;
  border: none;
  font-size: 14px;
  display: flex;
  align-items: center;
} */

.card-body .btn .mr-1 {
  margin-right: 5px;
}

.product-img-container {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}

.rounded-bottom-pc {
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.product-img-container img {
  transition: transform 0.3s ease-in-out;
  /* Smooth zoom transition */
}

.product-img-container:hover img {
  transform: scale(1.1);
  transition: transform 1s ease-in-out;
  /* Zoom effect */
}

.product-name {
  font-size: 16px !important;
}

.product-price {
  font-size: 14px !important;
}

.product-addtocard {
  font-size: 14px !important;
}

.product-img {
  width: 100%;
  height: auto;
  max-height: 40vh;
  object-fit: cover;
  transition: transform 1s ease-in-out;
}


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

  .product-name {
    font-size: 16px !important;
  }

  .product-price {
    font-size: 14px !important;
  }

  .product-addtocard {
    font-size: 14px !important;
  }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  /* Your styles here */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Your styles here */
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  /* Your styles here */
}